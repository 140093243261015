import {QbElement} from "@qb-assets-js/qb-elements/qb-element"

export class QbParticipants extends QbElement {
    // static shadow = {mode: 'open', sheets: ["qb-elements"]};
    //
    // constructor() {
    //     super();
    //     this.contentRoot.append(
    //         BUTTON({part: "button", type: "button", events: [this, {click: "toggle"}], aria: {label: _("Group overview")}}),
    //         // BUTTON({part: "video", aria: {label: _("Video chat")}, events: [this, {click: 'toggleVideo'}], title: _("Start video chat")}),
    //         DIV({part: "scroll"},
    //             SLOT({part: "slot"})
    //         )
    //     );
    // }

    handleEvent_toggleVideo(evt) {
        evt.currentTarget.setClass(Show.toggleVideo, "open");
    }

    handleEvent_addTeam(e) {
        console.log("addTeam");
    }


    handleEvent_toggle(e) {
        const nodes = [...this.parentElement.getElementsByTagName("qb-player")];
        if(this.toggleClass("open")) nodes.forEach(n=>{
            n.setAttribute('draggable', 'true');
            n.addEventListener('dragstart', n)
        });
        else nodes.forEach(n=>{
            n.removeAttribute('draggable');
            n.removeEventListener('dragstart', n)
        });
    }

    set participant(value) {
        const {itemtype, itemid, remove, ...data} = value;
        if(remove) return document.querySelectorAll(`[itemid="${itemid}"]`).forEach(n => n.remove());
        let node = this.querySelector(`[itemid="${itemid}"]`);
        if(!node) node = cT(`QB-${itemtype}`, {beforeend: this, itemid, itemtype});
        QbEntity.init(node, data).then(data=>node.update(data));
        node.finalize;
    }

    set participants(value) {
        [value].flat(3).forEach(v => this.participant = v);
    }
}

QbParticipants.register;

